import React from 'react';
import './Services.css'

const AboutUs = () => {
  return (
    <div className="services-container">
      <div className="container">
        <div className="services-header">
          <p className="services-title"style={{fontSize:'26px'}}>OUR SERVICES</p>
          <div className="breadcrumb">
            <a href="/" className="breadcrumb-link">Home</a> &gt; <span>Services</span>
          </div>
        </div>
        <p className="services-description">
         Leader in Contract, Contract to Hire, Locum, Travel, Per Diem and Direct Hire Staffing.
<br/><br/>
With a team of dedicated staffing professionals supported by in-depth industry experience and robust internal database of screened candidates, we are able to work on a wide range of staffing needs and provide options within a short span. Contact us for specific turnaround times on various jobs.
<br/><br/>
BluPeaks is one stop shop for all your staffing needs. Our Solutions team go beyond the services typically provided by a Tecnical staffing agency. We assist our clients in managing their entire staffing. BluPeaks coordinates with multiple vendors and streamlines your entire contingent workforce needs. </p><br/>
        <h3 className="focus-title">BluPeaks Areas of Focus</h3>
        <ul className="focus-list">
          Technical Staffing<br/>
          Cloud Infrastructure <br/>
          IT, Administrative, Operations<br/><br/>
          Below is the representative list of Technical jobs filled for our clients across the US.
        </ul><br/>
        <h3 className='service-priority'>What is your current priority?</h3>
        <ul className="priority-list">
          <li>Network Operation , Network Security</li>
          <li>Data Center , Migration </li>
          <li>Remote Jobs In The Technical Domain</li>
          <li>Software Professionals</li>
          <li>Cloud & IT domain</li>
          <li>Computer System Validation (CSV)</li>
          <li>Data Science</li>
          <li>Data Analyst</li>
          <li>SEO</li>
          <li>Web Creation</li>
          <li>Lnux & System Administration</li>
          
          <li>Quality Assurance Analyst</li>
         
          <li>Cyber Security</li>
          <li>JAVA , PYTHON , DEVOPS </li>
          <li>Programing Language </li>
        
          <li>Scientist – Various</li>
         
          
          <li>IT, Accounting and Administrative roles</li>
          <br></br>Whatever your needs are, we have talented candidates for it!!
        </ul>
      </div>
    </div>
  );
}

export default AboutUs;
