import React from "react"
import Ahome from "./Ahome"
import Slider from "../Slider/Slider"
import Services from "../Service/Services"
import Testimonials from "../Testimonials/Testimonials"

const Home =() =>{
    return (
        <div>
        <Slider/>
        <Ahome/>
        <Services/>
        <Testimonials/>
        </div>
         );
        }
        
export default Home