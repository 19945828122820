import React from 'react';
import './Footer.css'; // Ensure you create this CSS file

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-row">
          <div className="footer-col">
            <h2 className="footer-title">Contact Us</h2>
            <ul className="contact-details">
             
              <li>
                <i className="fas fa-phone contact-icon"></i> 
                Phone: 1-800-829-4933
              </li>
              <li>
                <i className="fas fa-phone contact-icon"></i> 
                Help Desk: 1-678-906-8699
              </li>
              <li>
  <i className="fas fa-envelope contact-icon"></i> 
  Email: info@blupeaks.com
</li>
              <li>
  <i className="fas fa-map-marker-alt contact-icon"></i> 
  Address: 9214 , Orlando, Florida, 32836
</li>
            </ul>
          </div>
          <div className="footer-col">
            <h2 className="footer-title">Quick Links</h2>
            <ul className="footer-links">
              <li><a href="#">Home</a></li>
              <li><a href="#">About Us</a></li>
              <li><a href="#">Our Services</a></li>
              <li><a href="#">Careers</a></li>
              <li><a href="#">Contact Us</a></li>
            </ul>
          </div>
          <div className="footer-col">
            <h2 className="footer-title">About Us</h2>
            <p>BluPeaks is a Business Enterprise with over 10 years of experience in End-to-End Recruitment and Software design and development.</p>
            <a className="read-more-button" href='aboutus'>Read More</a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2021, All Rights Reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
