import React, { useState } from 'react';
import './Contactus.css';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    phone: '',
    comment: ''
  });

  const [responseMessage, setResponseMessage] = useState(''); // To display the response message

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:5000/contact/submit-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      
      if (response.ok) {
        const data = await response.json();
        setResponseMessage(data.message); // Show success message
        console.log('Form submitted successfully:', data);
      } else {
        setResponseMessage('Failed to submit form.'); // Show error message
        console.error('Failed to submit form');
      }
    } catch (error) {
      setResponseMessage('An error occurred. Please try again.'); // Show error message
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="contact-container">
      <div className="container">
        <div className="contact-header">
          <p className="contact-title" style={{ fontSize: '24px' }}>CONTACT US</p>
          <div className="breadcrumb">
            <a href="/" className="breadcrumb-link">Home</a> &gt; <span>CONTACT US</span>
          </div>
        </div>
        <div className="contact-content">
          <div className="contact-info">
            <h3>BluPeaks</h3>
            <h3>Call Us</h3>
            <p>Phone: 1-800-829-4933</p>
          </div>
          <div className="contact-form">
            <p>Please fill below form and one of our client service leaders will reach out to you.</p>
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
              <label>
                Name *
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  style={{ width: '100%', marginBottom: '10px' }}
                />
              </label>
              <label>
                Company *
                <input
                  type="text"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                  required
                  style={{ width: '100%', marginBottom: '10px' }}
                />
              </label>
              <label>
                Email *
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  style={{ width: '100%', marginBottom: '10px' }}
                />
              </label>
              <label>
                Phone *
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  style={{ width: '100%', marginBottom: '10px' }}
                />
              </label>
              <label>
                Comment *
                <textarea
                  name="comment"
                  value={formData.comment}
                  onChange={handleChange}
                  required
                  style={{ width: '100%', height: '100px', marginBottom: '10px' }}
                />
              </label>
              <button type="submit" style={{ alignSelf: 'flex-start', backgroundColor: '#007bff', color: '#fff', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                Send
              </button>
            </form>
            {responseMessage && <p>{responseMessage}</p>} {/* Display response message */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
