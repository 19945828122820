import React, { useState } from 'react';
import './Career.css';

const FormComponent = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    resume: null
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      resume: e.target.files[0]
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create FormData object to hold form fields and file
    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('phone', formData.phone);
    formDataToSend.append('message', formData.message);
    formDataToSend.append('resume', formData.resume);

    try {
      // Send form data to the backend
      const response = await fetch('http://localhost:5000/career/submit-form', {
        method: 'POST',
        body: formDataToSend
      });

      const result = await response.json();
      if (response.ok) {
        alert("Form submitted successfully!");
      } else {
        alert("Failed to submit the form: " + result.message);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert("Error submitting the form");
    }
  };

  return (
    <div className="career-container">
      <div className="container">
        <div className="career-header">
          <p className="career-title" style={{fontSize:'28px'}}>CAREERS</p>
          <div className="breadcrumb">
            <a href="/" className="breadcrumb-link">Home</a> &gt; <span>CAREERS</span>
          </div>
        </div>
        <p className="career-description">
          We have requirements throughout US though most are in North East, Mid-Atlantic and south eastern states.
          <br />Even if you do not see an open position, it will be worth sending us your resume. Please include your location preference and if you will consider direct hire or contract positions.
        </p>
        <h2 className="career-title">Use the link below to send us the resume.</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Name *</label><br />
            <input type="text" name="name" value={formData.name} onChange={handleChange} style={{ height: '30px', width: '50%', maxWidth: '100%', resize: 'vertical' }} required />
          </div>
          <div>
            <label>Email *</label><br />
            <input type="email" name="email" value={formData.email} onChange={handleChange} style={{ height: '30px', width: '50%', maxWidth: '100%', resize: 'vertical' }} required />
          </div>
          <div>
            <label>Phone # *</label><br />
            <input type="tel" name="phone" value={formData.phone} onChange={handleChange} style={{ height: '30px', width: '50%', maxWidth: '100%', resize: 'vertical' }} required />
          </div>
          <div>
            <label>Message</label><br />
            <textarea name="message" value={formData.message} onChange={handleChange} style={{ height: '100px', width: '50%', maxWidth: '100%', resize: 'vertical' }} />
          </div>
          <div>
            <label>Upload Resume *</label><br />
            <input type="file" name="resume" onChange={handleFileChange} style={{ height: '40px', width: '50%', maxWidth: '100%', resize: 'vertical' }} required />
          </div>
          <div className="button-container">
            <button type="submit">Send</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormComponent;
