import React, { useState } from "react";
import "./Nav.css";
import { Link, NavLink } from "react-router-dom";

export const Nav = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <nav>
      <Link to="/" className="title">
        <div className="logo_content d-flex flex-row align-items-end justify-content-start">
          <div className="logo_img">
            <img src="/logo.png" alt="logo" />
          </div>
        </div>
      </Link>
      <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul className={menuOpen ? "open" : ""}>
        <li>
          <NavLink to="/" onClick={() => setMenuOpen(false)}>Home</NavLink>
        </li>
        <li>
          <NavLink to="/aboutus" onClick={() => setMenuOpen(false)}>About us</NavLink>
        </li>
        <li>
          <NavLink to="/mainservices" onClick={() => setMenuOpen(false)}>Our Services</NavLink>
        </li>
        <li>
          <NavLink to="/career" onClick={() => setMenuOpen(false)}>Careers</NavLink>
        </li>
        <li>
          <NavLink to="/contactus" onClick={() => setMenuOpen(false)}>Contact us</NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
