import React from "react";
import "./Services.css";
import { Link } from "react-router-dom";

const ServiceCard = ({ icon, title, description, buttonText, buttonStyle, buttonLink }) => {
  return (
    <div className="service-card">
      <div className="service-card-icon">{icon}</div>
      <h3 className="service-card-title">{title}</h3>
      <p className="service-card-description">{description}</p>
      <Link to={buttonLink} className="service-card-button" style={buttonStyle}>
        {buttonText}
      </Link>
    </div>
  );
};

const Services = () => {
  return (
    <div className="our-services">
      <h2 className="our-services-title">Our Services</h2>
      <p className="our-services-description">
        We Are Here to Provide You Awesome Service Always
      </p>
      <div className="service-cards">
        <ServiceCard
          icon={<img src="/experience.png" alt="RPO Services" />}
          title="RPO SERVICES"
          description="Our strategy is focused upon results-driven RPO services"
          buttonText="Read More"
          buttonLink="/mainservices"
        />
        <ServiceCard
          icon={<img src="/marketing.png" alt="US Staffing" />}
          title="US Staffing"
          description="We have many open positions for Staffing professionals..."
          buttonText="Read More"
          buttonLink="/mainservices"
          // buttonStyle={{ bottom: '-60px' }}
        />
        <ServiceCard
          icon={<img src="/search.png" alt="Training" />}
          title="Training"
          description="Take charge of your career. Let us help you find a career that"
          buttonText="Read More"
          buttonLink="/mainservices"
          // buttonStyle={{  bottom: '-60px'}}
        />
        <ServiceCard
          icon={<img src="/target.png" alt="Placement" />}
          title="Placement"
          description="A focused Training and placement Solutions provider"
          buttonText="Read More"
          buttonLink="/mainservices"
        />
      </div>
    </div>
  );
};

export default Services;
