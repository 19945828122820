import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Nav from "./Components/Nav";
import Home from './Components/Home/Home';
import './App.css';
import Footer from './Components/Footer';
import AboutUs from './Components/About/AboutUs';
import MainServices from './Components/Service/MainServices'
import Career from './Components/Career/Career'
import ContactUs from './Components/ContactUs/ContactUs';

function App() {
  return (
    <div>
      <Router>
        <Nav />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/aboutus' element={<AboutUs />} />
          <Route path='/mainservices' element={<MainServices/>} />
          <Route path='/career' element={<Career/>} />
          <Route path='/contactus' element={<ContactUs/>} />
        </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
