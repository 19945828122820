import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Slider.css';

const ImageSlider = () => {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div>
    <div className="slider-container">
      <Slider ref={sliderRef} {...settings}>
        <div className="slider-item">
          <img src="/banner1.jpg" alt="Slide 1" className="slider-image" />
        </div>
        <div className="slider-item">
          <img src="/banner2.jpg" alt="Slide 2" className="slider-image" />
        </div>
        <div className="slider-item">
          <img src="/banner3.jpg" alt="Slide 3" className="slider-image" />
        </div>
      </Slider>
    </div>
    </div>
  );
};

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'black', right: '35px' }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'black', left: '30px', zIndex: 1 }}
      onClick={onClick}
    />
  );
};

export default ImageSlider;
